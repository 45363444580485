import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-margin__tb cp-form__row cp-page__padding" }
const _hoisted_2 = { class: "cp-form-btn-actions__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_app_row = _resolveComponent("app-row")!
  const _component_point = _resolveComponent("point")!
  const _component_app_fieldset = _resolveComponent("app-fieldset")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    class: "cp-margin__top-40 cp-margin__bottom-40 cp-page__padding-sm",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createVNode(_component_app_col, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_fieldset, null, {
          title: _withCtx(() => [
            _createTextVNode("Настройки региона")
          ]),
          default: _withCtx(() => [
            _createVNode(_component_app_row, {
              class: "cp-height--full",
              direction: "column",
              style: {"padding":"12px 8px 10px 8px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_col, { grow: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_row, { gap: ['0', '8'] }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_form_input, {
                              modelValue: _ctx.name,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                              label: "Название",
                              required: "",
                              class: "cp-width__max--480"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_row, { gap: ['0', '8'] }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_point, {
                              model: _ctx.coords,
                              placeholder: 'Выберите точку',
                              class: "cp-margin__t-r-10 cp-width__auto cp-flex--grow-2",
                              onUpdate: _ctx.updatePoint
                            }, null, 8, ["model", "onUpdate"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isAdd)
          ? (_openBlock(), _createBlock(_component_form_button, {
              key: 0,
              text: _ctx.text.btnAdd,
              type: 'submit',
              class: "cp-margin__r--sx"
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_form_button, {
              key: 1,
              text: _ctx.text.btnSave,
              type: 'submit',
              class: "cp-margin__r--sx"
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        _createVNode(_component_form_button, {
          text: _ctx.text.btnCancel,
          bordered: true,
          onClick: _ctx.returnBefore
        }, null, 8, ["text", "onClick"])
      ])
    ])
  ], 544))
}