
import { Prop, Component, Vue } from '@/lib/decorator';
import ModalBlock from '@/components/Modal.vue';

import EditPoint from './_edit.vue';
import FormInput from '@/components/form/Input/index.vue';

import YandexMap from '@/lib/map/yandexMap';
import mapEntity from '@/lib/formFactory/mapEntity';

import RegionEntityModule from '@/store/settings/regions/entity.ts';

const {
  defaultValue: { default: defaultCoordinates },
} = new mapEntity('addressCoordinates');

let YANDEX_MAP: YandexMap = Object.create(null);

@Component({
  components: {
    ModalBlock,
    EditPoint,
    FormInput,
  },
})
export default class RegionsPointsEdit extends Vue {
  @Prop() model!: any;
  @Prop() placeholder = 'Выберите точку и радиус';

  coordinates = this.model && this.model.address ? this.model.coordinates : defaultCoordinates;
  point = this.model && this.model.address ? this.model.address : '';

  showMap = false;
  isInitMap = false;

  async initMap() {
    const mapWrapper = document.getElementById('map');

    if (!this.coordinates) {
      return false;
    }

    if (!mapWrapper) {
      setTimeout(() => {
        this.initMap();
      }, 300);

      return false;
    }

    YANDEX_MAP = new YandexMap();

    YANDEX_MAP.initMap(this.coordinates, 'map');

    if (this.coordinates.latitude && this.coordinates.longitude) {
      YANDEX_MAP.addPlaceMarker(this.coordinates);
    }

    YANDEX_MAP.addEventsClick();
  }

  updatePoint(point: { lat: number; lng: number }, address: string) {
    YANDEX_MAP.updatePlaceMarker([point.lat, point.lng]);
    YANDEX_MAP.entity.setCenter([point.lat, point.lng]);
    YANDEX_MAP.setPlaceMarkAddress(address);
  }

  updateAddress(value: any) {
    this.updatePoint(value.coordinates, value.address.value);
  }

  savePoint(value: any) {
    this.point = value.address;

    this.$emit('update', value);
  }

  updatePointCoordinates(event: any) {
    RegionEntityModule.updateSelectedAddress({
      latitude: event.detail[0],
      longitude: event.detail[1],
    });
  }

  async mounted() {
    await this.initMap();

    this.isInitMap = true;

    (document.querySelector('.default-layout__menu') as any).style.zIndex = '1';
  }

  destroyed() {
    (document.querySelector('.default-layout__menu') as any).style.zIndex = '9999';
  }

  created() {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }

  updated() {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }

  unmounted() {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }
}
