import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c9dfa66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-width--full" }
const _hoisted_2 = { class: "cp-map__block cp-map__block_fixed--50" }
const _hoisted_3 = {
  key: 0,
  id: "map",
  class: "cp-map__wrapper cp-br-10",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_edit_point = _resolveComponent("edit-point")!
  const _component_modal_block = _resolveComponent("modal-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_input, {
      modelValue: _ctx.point,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.point) = $event)),
      placeholder: _ctx.placeholder,
      readonly: "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMap = true)),
      class: "cp-margin__bottom-0"
    }, null, 8, ["modelValue", "placeholder"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_ctx.isInitMap)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.showMap]
    ]),
    _withDirectives(_createVNode(_component_modal_block, {
      class: "cp-points__modal",
      title: "Редактировать точку",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showMap = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_edit_point, {
          model: _ctx.model,
          onUpdate: _ctx.updateAddress,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showMap = false)),
          onSave: _ctx.savePoint
        }, null, 8, ["model", "onUpdate", "onSave"])
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.showMap]
    ])
  ]))
}