
import { Component, Vue, Watch } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';

import AddBlock from './Add.vue';
import EditBlock from './Edit.vue';

import RegionsModule from '@/store/settings/regions';
import SettingsModule from '@/store/settings';
import UserModule from '@/store/user';
import IconFont from '@/components/icons/IconFont.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    AppTable,
    IconFont,
    TabsNav,
    AppHeaderBtnAdd,
    AddBlock,
    EditBlock,
    ArrowReturn,
  },
})
export default class RegionsMain extends Vue {
  activeRow = null;

  get tabsNav() {
    return SettingsModule.tabsNav;
  }

  get currentTab() {
    return SettingsModule.allTabs.regions.id;
  }

  get canIsEdit() {
    return UserModule.userHasPermission('CAN_EDIT_REGION');
  }

  get isAdd() {
    return SettingsModule.isAdd;
  }

  get isEdit() {
    return SettingsModule.isEdit;
  }

  get settings() {
    return RegionsModule.pageSettings;
  }

  @Watch('activeRow')
  changeColorRow(el: HTMLElement, oldEl: HTMLElement) {
    if (el) {
      el.classList.add('app-table-body-row--selected');
    }

    if (oldEl) {
      oldEl.classList.remove('app-table-body-row--selected');
    }
  }

  cancelEdit() {
    this.activeRow = null;
  }

  toAdd() {
    RegionsModule.updateIsAdd(true);
  }

  toEdit(id: string, index: number) {
    const table = this.$refs.table as any;
    if (table) {
      this.activeRow = table.$el.querySelector(`.app-table-body-row--index-${index}`);
    }
    RegionsModule.updateIsEdit({ isEdit: true, id: id.toString() });
  }

  returnBefore() {
    this.cancelEdit();
    RegionsModule.updateIsAdd(false);
    RegionsModule.updateIsEdit({ isEdit: false, id: '' });
  }

  selectAmount(value: string) {
    RegionsModule.updatePageAmountItems(value);
  }

  created() {
    RegionsModule.init();
  }

  mounted() {
    SettingsModule.initTabsItems();
  }
}
