
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';
import FormInput from '@/components/form/Input/index.vue';
import FormButton from '@/components/form/Button/index.vue';

import RegionEntityModule from '@/store/settings/regions/entity';
import { strings } from '@/lib/stringConst';
import SettingsModule from '@/store/settings';

import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';

import Point from './Point/index.vue';

@Component({
  emits: ['returnBefore'],
  components: {
    FormInput,
    FormButton,
    AppCol,
    AppRow,
    AppFieldset,
    Point,
  },
})
export default class RegionsEntityForm extends FormEditor {
  @Prop() typeAction!: string;

  name = this.model.name;
  lat = this.model.lat ?? null;
  long = this.model.long ?? null;
  address = this.model.address ?? '';

  get text() {
    return strings;
  }

  get model() {
    return RegionEntityModule.model;
  }

  get isAdd() {
    return SettingsModule.isAdd;
  }

  get isEdit() {
    return SettingsModule.isEdit;
  }

  get coords() {
    return {
      address: this.address ?? '',
      coordinates: {
        latitude: this.lat,
        longitude: this.long,
      },
    };
  }

  returnBefore() {
    this.$emit('returnBefore');
  }

  updatePoint(value: any) {
    this.lat = value.addressCoordinates.latitude;
    this.long = value.addressCoordinates.longitude;
    this.address = value.address;
  }

  get textFields() {
    return {
      name: this.name,
      lat: this.lat,
      long: this.long,
    };
  }

  async add() {
    try {
      await RegionEntityModule.addNew(this.textFields);
      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async edit() {
    try {
      await RegionEntityModule.saveUpdate(this.textFields);
    } catch (e) {
      console.debug('error');
    }
  }
}
