import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "cp-flex" }
const _hoisted_2 = { class: "cp-flex cp-flex--align-center cp-flex--justify-between" }
const _hoisted_3 = { class: "app-table-bold-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_arrow_return = _resolveComponent("arrow-return")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_add_block = _resolveComponent("add-block")!
  const _component_edit_block = _resolveComponent("edit-block")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-page__settings"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, {
        grow: 0,
        class: "cp-admin-title-tab__wrapper"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tabs_nav, {
            items: _ctx.tabsNav,
            "active-id": _ctx.currentTab
          }, null, 8, ["items", "active-id"]),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.canIsEdit)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (!_ctx.isAdd && !_ctx.isEdit)
                    ? (_openBlock(), _createBlock(_component_app_header_btn_add, {
                        key: 0,
                        onClick: _ctx.toAdd
                      }, null, 8, ["onClick"]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "cp-arrow-return",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returnBefore && _ctx.returnBefore(...args)))
                      }, [
                        _createVNode(_component_arrow_return, { color: "#9B9BC7" })
                      ]))
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_app_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_app_row, { class: "block-radius" }, {
            default: _withCtx(() => [
              (Object.keys(_ctx.settings.table).length)
                ? (_openBlock(), _createBlock(_component_app_table, {
                    key: 0,
                    ref: "table",
                    class: "app-table--row-pointer grow-1",
                    style: {"height":"calc(100vh - 54px)"},
                    headers: _ctx.settings.table.titles,
                    items: _ctx.settings.table.rows,
                    page: _ctx.settings.table.pagination.currentPage,
                    "total-pages": _ctx.settings.table.pagination.totalPages,
                    "total-items": _ctx.settings.table.totalItems,
                    "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
                    "is-hover": true,
                    onClickRow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toEdit($event.item.id, $event.indexItem))),
                    onChangePage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
                    onSelectAmount: _ctx.selectAmount
                  }, {
                    "item-name": _withCtx(({ value }) => [
                      _createElementVNode("span", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(value), 1),
                        _createVNode(_component_icon_font, {
                          class: "app-table-icon app-table-icon--row-hover",
                          icon: "pencil"
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount"]))
                : _createCommentVNode("", true),
              _createVNode(_component_app_col, null, {
                default: _withCtx(() => [
                  (_ctx.isAdd)
                    ? (_openBlock(), _createBlock(_component_add_block, { key: 0 }))
                    : (_ctx.isEdit)
                      ? (_openBlock(), _createBlock(_component_edit_block, {
                          key: 1,
                          onCancel: _ctx.cancelEdit
                        }, null, 8, ["onCancel"]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}