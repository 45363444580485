/**
 * Regions store.
 *
 */

import { Module, Action, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getRegionsList } from '@/api/region';
import SettingsModule from '@/store/settings';
import RegionEntityModule from './entity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

export const MODULE_NAME = 'regions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class RegionsModule extends PageBaseModule {
  constructor(module: RegionsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'regions/updatePage';
    page.setTitle('Регионы');
    page.setTitleAdd('Добавить регион');
    this.pageSettings = page.values;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const tableInfo = uiTable.getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getRegionsList(this.pageSettings.pageCurrent, itemsQuery);
      await this.setList(result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  updateIsAdd(isAdd: boolean) {
    RegionEntityModule.resetModel();
    SettingsModule.SET_IS_EDIT(false);
    RegionEntityModule.SET_ID('');
    SettingsModule.SET_IS_ADD(isAdd);
  }

  @Action({ rawError: true })
  updateIsEdit(params: { isEdit: boolean; id: string }) {
    RegionEntityModule.resetModel();
    RegionEntityModule.SET_ID(params.id);
    SettingsModule.SET_IS_ADD(false);
    SettingsModule.SET_IS_EDIT(params.isEdit);

    if (params.id && params.id.length > 0) {
      RegionEntityModule.getInfoById();
    }
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }
}

export default getModule(RegionsModule);
