import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createBlock(_component_form_block, {
    "type-action": "add",
    onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save($event))),
    onReturnBefore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.returnBefore($event)))
  }))
}