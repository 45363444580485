/**
 * Shop entity store.
 *
 */

import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import RegionsModule from './index';
import { addNewRegion, saveRegion, getRegionById } from '@/api/region';

import { strings } from '@/lib/stringConst';
import { getAddressViaCoordinates } from '@/api/geo';
import { regionEntity } from '@/interfaces/regionResponse.interface.ts';

export const MODULE_NAME = 'region';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class RegionEntityModule extends EntityBaseModule {
  model = {
    name: '',
    lat: 0,
    long: 0,
    address: '',
  };
  selectedAddress = {};

  constructor(module: RegionEntityModule) {
    super(module);
  }

  @Mutation
  SET_NAME(value: string) {
    this.model.name = value;
  }

  @Mutation
  SET_LATITUDE(value: number) {
    this.model.lat = value;
  }

  @Mutation
  SET_LONGITUDE(value: number) {
    this.model.long = value;
  }

  @Mutation
  SET_ADDRESS(value: string) {
    this.model.address = value;
  }

  @Mutation
  SET_SELECTED_ADDRESS(address: Record<string, string | number>) {
    this.selectedAddress = address;
  }

  @Action({ rawError: true })
  async getInfoById() {
    try {
      const result = await getRegionById(this.id);
      this.setInfo(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setInfo(info: regionEntity) {
    RegionsModule.updateTitleEdit(info.name);

    if (info.centerCoordinates) {
      this.context.commit('SET_LATITUDE', info.centerCoordinates.latitude);
      this.context.commit('SET_LONGITUDE', info.centerCoordinates.longitude);
      this.context.commit(
        'SET_ADDRESS',
        await getAddressViaCoordinates(info.centerCoordinates.latitude, info.centerCoordinates.longitude)
      );
    }

    this.context.commit('SET_NAME', info.name);
    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async updateSelectedAddress(coordinates: { latitude: number; longitude: number }) {
    const addressString = await getAddressViaCoordinates(coordinates.latitude, coordinates.longitude);
    if (addressString !== '') {
      this.context.commit('SET_SELECTED_ADDRESS', {
        address: addressString,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      });
    }
  }

  @Action({ rawError: true })
  async addNew(data: { name: string }) {
    try {
      const result = await addNewRegion(data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Регион создан', type: 'ok' });
        RegionsModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(data: { name: string }) {
    try {
      const result = await saveRegion(this.id, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
        RegionsModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  resetModel() {
    RegionsModule.updateTitleEdit('');
    this.context.commit('SET_NAME', '');
    this.context.commit('SET_LATITUDE', 0);
    this.context.commit('SET_LONGITUDE', 0);
    this.context.commit('SET_ADDRESS', '');
    this.updateIsLoading(true);
  }
}

export default getModule(RegionEntityModule);
