
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';

import RegionsModule from '@/store/settings/regions';

@Component({
  components: {
    FormBlock,
  },
})
export default class RegionsEntityAdd extends Form {
  returnBefore() {
    RegionsModule.updateIsAdd(false);
  }
}
