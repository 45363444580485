import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-modal-filter-options cp-form__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_autocomplete = _resolveComponent("form-autocomplete")!
  const _component_form_button = _resolveComponent("form-button")!
  const _component_modal_btn_bottom = _resolveComponent("modal-btn-bottom")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_form_autocomplete, {
        modelValue: _ctx.address,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address) = $event)),
        placeholder: "Выберите адрес",
        required: "",
        icon: "marker",
        "icon-size": "20",
        class: "cp-margin__b--small cp-width--full",
        "no-choise": "",
        "hide-empty-value": "",
        items: _ctx.addressesItems,
        loading: _ctx.addressesLoading,
        "react-model": true,
        onSearch: _ctx.searchAddress,
        onChange: _ctx.updateAddress,
        onSelect: _ctx.updateSelectedAddress
      }, null, 8, ["modelValue", "items", "loading", "onSearch", "onChange", "onSelect"])
    ]),
    _createVNode(_component_modal_btn_bottom, { position: "end" }, {
      default: _withCtx(() => [
        _createVNode(_component_form_button, {
          text: 'Изменить',
          outline: true,
          type: 'submit'
        })
      ]),
      _: 1
    })
  ], 544))
}