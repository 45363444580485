
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import RegionsModule from '@/store/settings/regions';
import RegionEntityModule from '@/store/settings/regions/entity';

@Component({
  components: {
    FormBlock,
    Loading,
  },
})
export default class RegionsEntityEdit extends Form {
  get isLoading() {
    return RegionEntityModule.isLoading;
  }

  returnBefore() {
    this.$emit('cancel');
    RegionsModule.updateIsAdd(false);
  }
}
